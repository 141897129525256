import React from 'react'
import { hasWindow } from '../util/dom'
import {
  Map,
  AttributionControl,
  CircleMarker,
  Popup,
  LayersControl,
  TileLayer,
} from 'react-leaflet'
import { Link, graphql } from 'gatsby'
import NoSSR from 'react-no-ssr'
import Layout from '../components/layout'
import SEO from '../components/seo'
import parse from 'wellknown'
import './index.css'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'leaflet.markercluster'
import LocateControl from '../components/locate'

const Loading = () => <div>Loading...</div>

export default ({ data }) => {
  // if there is no window, we cannot render this component
  if (!hasWindow) {
    return null
  }

  // https://github.com/PaulLeCam/react-leaflet/blob/master/example/components/layers-control.js
  const { BaseLayer, Overlay } = LayersControl

  return (
    <Layout>
      <SEO title="Mesilate kaart" keywords={[`mesila`, `mesindus`]} />
      <h3>Mesilate kaart</h3>
      <p>
        Kokku on PRIA's registreeritud {data.allApiariesCsv.totalCount} mesilat.
      </p>
      <Map
        key={Math.random()}
        className="leaflet-container"
        center={[58.58, 25.53]}
        zoom={7}
        minZoom={7}
        maxZoom={18}
        attributionControl={false}
        // Add some speed: https://stackoverflow.com/a/54006851
        preferCanvas={true}
      >
        {/* https://stackoverflow.com/questions/57517001/react-leaflet-with-leaflet-locatecontrol */}
        <LocateControl />
        <AttributionControl position="bottomright" prefix={false} />
        <LayersControl position="topright">
          <BaseLayer checked name="Kaart">
            <TileLayer
              attribution="Kaart: Maa-amet | Andmed: PRIA"
              url="https://tiles.maaamet.ee/tm/tms/1.0.0/kaart@GMC/{z}/{x}/{-y}.png"
              // attribution="Kaart: OpenStreetMap | Andmed: PRIA"
              // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Foto">
            <TileLayer
              // attribution="Kaart: Esri | Andmed: PRIA"
              // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution="Kaart: Maa-amet | Andmed: PRIA"
              url="https://tiles.maaamet.ee/tm/tms/1.0.0/foto@GMC/{z}/{x}/{-y}.png"
            />
          </BaseLayer>
          <Overlay name="Hübriid">
            <TileLayer
              // url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png"
              url="https://tiles.maaamet.ee/tm/tms/1.0.0/hybriid@GMC/{z}/{x}/{-y}.png"
            />
          </Overlay>
          <Overlay checked name="Mesilad">
            <NoSSR fallback={<Loading />}>
              <MarkerClusterGroup
                disableClusteringAtZoom={13}
                chunkedLoading={true}
              >
                {data.allApiariesCsv.edges.map(({ node }, id) => {
                  // Convert WKT points to JSON lng, lat array.
                  let geometry = parse(node.geometry)
                  let lat = geometry['coordinates'][0]
                  let lng = geometry['coordinates'][1]

                  let path = '/apiary/' + node.pohi_nr

                  // Join phone fields.
                  let phone = [node.reg_mobiil, node.reg_telefon]
                    .filter(function(item) {
                      return item != null
                    })
                    .join(' ')

                  let markersComponent
                  markersComponent = (
                    <CircleMarker
                      key={id}
                      center={[lng, lat]}
                      fillOpacity={0.7}
                      fillColor={`#ffcc00`}
                      color={`#FFFFFF`}
                      weight={1}
                      opacity={1}
                      radius={16}
                    >
                      <Popup>
                        <p>
                          <strong>
                            <Link to={path}>
                              {node.pohi_nr} {node.aadr_aadress}
                            </Link>
                          </strong>
                          <br />
                          <strong>Mesiniku telefon: </strong>
                          {phone}
                          <br />
                          <strong>PRIA's alates: </strong>
                          {node.reg_kp}
                          <br />
                        </p>
                      </Popup>
                    </CircleMarker>
                  )
                  return markersComponent
                })}
              </MarkerClusterGroup>
            </NoSSR>
          </Overlay>
        </LayersControl>
      </Map>
    </Layout>
  )
}

export const query = graphql`
  query {
    allApiariesCsv(
      sort: { fields: [reg_kp], order: DESC }
      filter: { staatus_tekst: { eq: "Kehtiv" } }
    ) {
      totalCount
      edges {
        node {
          id
          pohi_nr
          aadr_aadress
          aadr_kyla_alev
          aadr_valdlinn_tekst
          aadr_maakond_tekst
          reg_kp(formatString: "DD. MMMM YYYY", locale: "et-EE")
          reg_mobiil
          reg_telefon
          geometry
          staatus_tekst
        }
      }
    }
  }
`
